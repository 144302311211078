.menu-size {
    font-size: 1.2rem !important;
    font-weight: 800 !important;
}

.contact {
    font-size: 1.4rem !important;
    font-weight: 800;
}

.breadcrumb-subtitle {
    font-size: 2.2rem;
    font-weight: 100;
}

.font-20 {
    font-size: 20px !important;
}
.font-18 {
    font-size: 18px !important;
}

.reference-img {
    background-color: #FFFFFF;
    height: 157px !important; object-fit: contain;
    padding-right: 10px;
    padding-left: 10px;

}

.reference-img-side{
    height: 140px !important;
    object-fit: contain;
}

.min-230 {
    min-height: 230px !important;
}

.sitemap-box {
    padding: 0px 5px 0px 20px !important;
}

.card-container-custom {
    display: flex;
}

.card-custom {
    flex: 1;
    height: 100%;
}

.submenu-title {
    font-size: 1.0rem !important;
    font-weight: 600 !important;
}


@media (max-width: 576px) {
    .custom-hidden-sm {
        display: none !important;
    }

    .mobile-slider-text {
        font-size: 2.0rem !important;
        padding-bottom: 50px;
    }

    .mobile-slider-text-s2 {
        font-size: 2.5rem !important;
        padding-bottom: 50px;
    }
}

@media (min-width: 1200px){
    .menu-container {
        min-width: 1190px;}
}



@media (min-width: 10in) and (max-width: 13in) {
    .custom-nav-link {
        font-size: 0.8rem;
        padding:30px 3px !important;

    }
    .logo-title {
        display: none !important;
    }
}

@media (min-width: 768px) {
    .custom-nav-link {
        padding: 30px 5px !important;
    }
}

